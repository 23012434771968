<template>
<div class="out-cont">
  <div class="in-cont">
    <div class="data-cont">
      <div class="box shadow">
        <div class="b-name">
          {{info.contractName}}
        </div>
        <div class="form-group line">
          <div class="label">合同号：</div>
          <div class="cont cont-text">
            {{info.contractNo}}
          </div>
        </div>
        <div class="form-group line">
          <div class="label">IMEI码：</div>
          <div class="cont cont-text">
            {{info.imei}}
          </div>
        </div>
        <!-- <div class="form-group line">
          <div class="label">品牌型号：</div>
          <div class="cont cont-text">
            苹果11
          </div>
        </div> -->
      </div>

      <div class="box shadow">
        <div class="form-group">
          <div class="label">损坏情况：</div>
          <div class="cont">
            <FormCheck :list="phoneStatus" :val="form.applyInfo.damageConditions" @change="boxVal($event, 'damageConditions')"></FormCheck>
          </div>
        </div>
        <div class="form-group">
          <div class="label">故障原因：</div>
          <div class="cont">
            <FormCheck :list="malfunction" :val="form.applyInfo.damageReasons" @change="boxVal($event, 'damageReasons')"></FormCheck>
          </div>
        </div>
        <div class="form-group">
          <div class="label">故障描述：</div>
          <div class="cont textarea-out">
          <cube-textarea class="textarea" v-model="form.applyInfo.damageDesc" placeholder="请输入关于故障的详细描述" :maxlength="150"></cube-textarea>
          </div>
        </div>
      </div>

      <div class="box shadow">
        <div class="b-name2">
          身份证明
        </div>
        <div class="box-file-cont">
          <UpFile 
            @updata="upFile($event, 'identityResIds', 0)"
            :img="defaultImg.identityResIds[0]"
            label="身份证正面"
            :master="true"></UpFile>

          <UpFile 
            @updata="upFile($event, 'identityResIds', 1)"
            :img="defaultImg.identityResIds[1]"
            label="身份证反面"
            :master="true"></UpFile>
        </div>
      </div>
      <div class="box shadow">
        <div class="b-name2">
          损坏照片
        </div>
        <div class="box-file-cont">
          <UpFile 
            @updata="upFile($event, 'damageResIds', 0)"
            :img="defaultImg.damageResIds[0]"
            label="正面"
            :master="true"></UpFile>

          <UpFile 
            @updata="upFile($event, 'damageResIds', 1)"
            :img="defaultImg.damageResIds[1]"
            label="背面"
            :master="true"></UpFile>
          
          <UpFile 
            @updata="upFile($event, 'damageResIds', 2)"
            :img="defaultImg.damageResIds[2]"
            label="其他"
            :master="false"></UpFile>
        </div>
      </div>
      <div class="box shadow">
        <div class="b-name2">
          购买凭证
        </div>
        <div class="box-file-cont">
          <UpFile 
            @updata="upFile($event, 'buyResIds', 0)"
            :img="defaultImg.buyResIds[0]"
            label="购买凭证"
            :master="true"></UpFile>
        </div>
      </div>

      <div class="box shadow">
        <div class="b-name2">
          寄件地址
        </div>
        <div class="form-group line">
          <div class="label">姓名：</div>
          <div class="cont">
            <input type="text" v-model="form.linkmanInfo.name" placeholder="请填写您的名字">
          </div>
        </div>
        <div class="form-group line">
          <div class="label mini">电话：</div>
          <div class="cont">
            <input type="text" v-model="form.linkmanInfo.mobile" placeholder="请填写可以联系到您的有效电话号码">
          </div>
        </div>
        <div class="form-group line">
          <div class="label">省市区：</div>
          <div class="cont">
            <city ref="city" 
              :province="form.linkmanInfo.province"
              :city="form.linkmanInfo.city"
              :county="form.linkmanInfo.area"
              @change="upCity"></city>
          </div>
        </div>
        <div class="form-group line">
          <div class="label">详细地址：</div>
          <div class="cont">
            <input type="text" v-model="form.linkmanInfo.address" placeholder="请填写您的详细地址">
          </div>
        </div>
      </div>

      <div class="form-check" v-show="type != 'view'">
        <div class="icon" :class="{'on': readyHide}" @click="checkHide">
          <div class="forn-check"></div>
        </div>
        <div class="text" @click="explainShow = true">同意按照符合手机《服务条款说明》</div>
      </div>

      <div class="btns">
        <cube-button class="btn" @click="goNext" v-show="type != 'view'">确认提交</cube-button>
        <cube-button class="btn" @click="$router.go(-1)" v-show="type == 'view'">返回</cube-button>
      </div>

    </div>

    <Explain v-if="explainShow" :rowid="info.serviceLicenceId" @hide="hideExplain"></Explain>

  </div>


</div>
</template>

<script>
import FormCheck from '@/components/FormCheck.vue'
import FormRadio from '@/components/FormRadio.vue'
import city from '@/components/city.vue'
import UpFile from '@/components/UpFile.vue'
import Explain from '@/views/service/Explain.vue'

export default {
  name: 'dataText',
  components: {
    FormCheck,
    FormRadio,
    city,
    UpFile,
    Explain
  },
  data () {
    return {
      id: this.$route.query.id, // 合同ID
      contractNo: this.$route.query.contractNo, // 合同号
      type: this.$route.query.type, // 类型
      applyId: this.$route.query.applyId, // 申请ID

      info: {},

      phoneStatus: [], // 手机状态
      malfunction: [], // 故障原因

      defaultImg: {
        buyResIds: [''], // 购买凭证资源ID
        damageResIds: ['', '', ''], // 损坏照片资源ID
        identityResIds:	['', ''], // 身份证明资源ID
      },

      form: {
        applyInfo: {
          damageConditions: [], // 损坏情况
          damageReasons: [], // 损坏原因
          damageDesc: '', // 故障描述
        },
        contractInfo: {
          contractName: '', // 合同名称-合同服务项
          contractNo: '', // 合同编号
          customerName: '', // 客户姓名
          endTime: '', // 有效期结束时间
          imei: '', // imei
          contractItemId: '', // 合同项RowId
          startTime: '', // 有效期开始时间
        },
        imageInfo: {
          buyResIds: [''], // 购买凭证资源ID
          damageResIds: ['', '', ''], // 损坏照片资源ID
          identityResIds:	['', ''], // 身份证明资源ID
        },
        linkmanInfo: { 
          address: '', // 详细地址
          province: '', // 省ID
          city: '', // 市ID
          area: '', // 县ID
          mobile: '', // 联系电话
          name: '', // 寄件人名称
        },
        operatorId: '', // 当前操作人ID
      },
      readyHide: false,
      explainShow: false,

      // 验证规则
      validateRoom: {
        "phoneStatus": '请选择手机状态',
        "malfunction": '请选择故障原因',
        "name": "请输入收件人姓名",
        "telephone": "请输入收件人电话",
        "province": "请选择收件人省市区",
        "address": "请输入收件人详细地址",
      },
      


    }
  },
  mounted () {
    this.$store.commit('updateLoader', true);
    this.getBeforeSubmitApply();
    this.getInfo();

    if(this.$route.query.type == 'edit' || this.$route.query.type == 'view'){
      // 修改模式
      this.getEdit()
    }

  },
  methods: {
    /**
     * 阅读隐私条款
     */
    checkHide () {
      this.readyHide ? this.readyHide = false : this.readyHide = true;
    },
    hideExplain (val) {
      this.explainShow = false;
      if(val){
        this.readyHide = true;
      }
    },
    /**
     * 获取合同详情
     */
    getInfo () {
      const vm = this;
      let postData = {
        contractItemId: this.id
      }
      vm.$store.commit('updateLoader', true);
      this.$http
        .post("/channelsys/contractService/queryServiceDetail", postData)
        .then(function(response) {
          vm.$store.commit('updateLoader', false);
          if (response.data.header.code == "A1000") {
            vm.info = response.data.body;
            vm.form.contractInfo = {
              contractName: vm.info.contractName, // 合同名称-合同服务项
              contractNo: vm.info.contractNo, // 合同编号
              customerName: vm.info.customerName ? vm.info.customerName : '', // 客户姓名
              endTime: vm.info.endTime, // 有效期结束时间
              imei: vm.info.imei, // imei
              contractItemId: vm.info.contractItemId, // 合同项RowId
              startTime: vm.info.startTime, // 有效期开始时间
            }
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$store.commit('updateLoader', false);
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    /**
     * 获取手机状态
     */
    getBeforeSubmitApply () {
      const vm = this;
      let postData = {}
      this.$http
        .post("/channelsys/apply/beforeSubmitApply", postData)
        .then(function(response) {
          if (response.data.header.code == "A1000") {
            // 手机状态
            vm.phoneStatus = response.data.body.damageConditions.map(e=>{
              return {
                id: e.state,
                name: e.desc
              }
            })
            // 故障原因
            vm.malfunction = response.data.body.damageReasons.map(e => {
              return {
                id: e.state,
                name: e.desc
              }
            })

          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    /**
     * 省市区选择
     */
    showCity() {
      this.$refs.city.$refs.drawer.show()
    },
    upCity (obj) {
      this.form.linkmanInfo.province = obj.province;
      this.form.linkmanInfo.city = obj.city;
      this.form.linkmanInfo.area = obj.county;
    },
    /**
     * 组件值确定
     */
    boxVal (val, type) {
      this.form.applyInfo[type] = val;
    },
    upFile (val, key, num) {
      this.form.imageInfo[key][num] = val
    },
    /**
     * 获取修改数据
     */
    getEdit () {
      const vm = this;
      if(! this.applyId){
        this.$createToast({
          txt: '无申请信息',
          type: 'error'
        }).show()
        return
      }
      let postData = {
        applyId: this.applyId,
      }
      this.$http
        .post("/channelsys/apply/queryApplyDetails", postData)
        .then(function(response) {
          if (response.data.header.code == "A1000") {
            // for(let key in vm.form){
            //   if(response.data.body[key]){
            //     vm.form[key] = response.data.body[key];
            //   }
            // }
            // 地址
            vm.form.linkmanInfo = response.data.body.applyLinkman;
            vm.form.linkmanInfo.name = response.data.body.applyLinkman.linkman;
            // 资源
            vm.form.imageInfo.buyResIds = response.data.body.apply.buyPics.map(e => {
              return e.resId
            })
            vm.defaultImg.buyResIds = response.data.body.apply.buyPics.map(e => {
              return e.resAddress
            })

            vm.form.imageInfo.damageResIds = response.data.body.apply.damagePics.map(e => {
              return e.resId
            })
            vm.defaultImg.damageResIds = response.data.body.apply.damagePics.map(e => {
              return e.resAddress
            })

            vm.form.imageInfo.identityResIds = response.data.body.apply.identityPics.map(e => {
              return e.resId
            })
            vm.defaultImg.identityResIds = response.data.body.apply.identityPics.map(e => {
              return e.resAddress
            })

            // 损坏情况
            vm.form.applyInfo.damageConditions = response.data.body.apply.damageConditions.map(e => {
              return e.value
            })
            vm.form.applyInfo.damageReasons = response.data.body.apply.damageReasons.map(e => {
              return e.value
            })
            vm.form.applyInfo.damageDesc = response.data.body.apply.remark;
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    /**
     * 提交
     */
    goNext () {
      const vm = this;
      // 手机损坏原因
      if(this.form.applyInfo.damageConditions.length == 0){
        this.$createToast({
          txt: '请选择手机损坏情况',
          type: 'warn'
        }).show()
        return
      }
      if(this.form.applyInfo.damageReasons.length == 0){
        this.$createToast({
          txt: '请选择手机故障原因',
          type: 'warn'
        }).show()
        return
      }
      // 文件
      if(this.form.imageInfo.identityResIds[0] == ''){
        this.$createToast({
          txt: '请上传身份证正面',
          type: 'warn'
        }).show()
        return
      }
      if(this.form.imageInfo.identityResIds[1] == ''){
        this.$createToast({
          txt: '请上传身份证背面',
          type: 'warn'
        }).show()
        return
      }
      if(this.form.imageInfo.buyResIds[0] == ''){
        this.$createToast({
          txt: '请上传购买凭证',
          type: 'warn'
        }).show()
        return
      }
      if(this.form.imageInfo.damageResIds[0] == ''){
        this.$createToast({
          txt: '请上传手机正面照片',
          type: 'warn'
        }).show()
        return
      }
      if(this.form.imageInfo.damageResIds[1] == ''){
        this.$createToast({
          txt: '请上传手机背面照片',
          type: 'warn'
        }).show()
        return
      }
      // 寄件地址
      if(this.form.linkmanInfo.name == ''){
        this.$createToast({
          txt: '请输入姓名',
          type: 'warn'
        }).show()
        return
      }
      if(this.form.linkmanInfo.mobile == ''){
        this.$createToast({
          txt: '请输入手机号',
          type: 'warn'
        }).show()
        return
      }else if(! this.$utils.isPhone(this.form.linkmanInfo.mobile)){
        this.$createToast({
          txt: '手机号格式错误',
          type: 'warn'
        }).show()
        return
      }
      if(this.form.linkmanInfo.city == ''){
        this.$createToast({
          txt: '请选择省市区',
          type: 'warn'
        }).show()
        return
      }
      if(this.form.linkmanInfo.address == ''){
        this.$createToast({
          txt: '请输入详细地址',
          type: 'warn'
        }).show()
        return
      }
      if(! this.readyHide){
        this.$createToast({
          txt: '请查看手机服务条款',
          type: 'warn'
        }).show()
        return
      }

      

      let postData = this.form;
      let url = "/channelsys/apply/submitApply";
      if(this.applyId){
        postData.applyInfo.applyId = this.applyId;
        url = "/channelsys/apply/resubmitApply";
      }
      
      vm.$store.commit('updateLoader', true);
      this.$http
        .post(url, postData)
        .then(function(response) {
          vm.$store.commit('updateLoader', false);
          if (response.data.header.code == "A1000") {
            vm.$router.replace('/ask/success')
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$store.commit('updateLoader', false);
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
  },
}
</script>

<style lang="scss" scoped>
.out-cont{
  height: 100vh;
  position: relative;
  box-sizing: border-box;
  background: #F8F8F8;
  .in-cont{
    height: 100vh;
    overflow-y: scroll;
  }
}
.data-cont{
  padding: 30px 0 0 0;
}
.box{
  box-sizing: border-box;
  background-color: #fff;
  width: 690px;
  background: #FFFFFF;
  border-radius: 12px;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 0 30px;
  .form-group{
    border-top: 1px solid #DEDEDE;
    &:first-child{
      border: none;
    }
  }
}
.box-file-cont{
  display: flex;
  border-top: 1px solid #EBEBEB;
  padding: 30px 0;
  justify-content: flex-start;
  &>div{
    margin-left: 30px;
    &:first-child{
      margin-left: 0;
    }
  }
}


.b-name{
  min-height: 50px;
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 50px;
  padding-top: 24px;
  padding-bottom: 14px;
}
.b-name2{
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 44px;
  padding: 26px 0 30px 0;
}


.form-group{
  font-size: 28px;
  color: #333333;
  line-height: 40px;
  .label{
    padding: 24px 0;
  }
  &.line{
    display: flex;
    .label{
      width: 160px;
      &.mini{
        width: 120px;
      }
    }
    .cont{
      flex: 10;
      text-align: right;
      input{
        display: block;
        width: 100%;
        text-align: right;
        padding: 24px 0;
        height: 40px;
        border: none;
        outline: none;
        list-style: none;
      }
    }
    .cont-text{
      padding: 24px 0;
    }
  }
}
.on-text{
  color: #1F955B;
  line-height: 90px;
}
.select{
  display: flex;
  text-align: right;
  padding: 30px 0;
  .select-text{
    flex: 10;
  }
  .select-icon{
    padding-left: 18px;
    padding-top: 4px;
    img{
      display: block;
      height: 30px;
      width: 18px;
    }
  }
}
.check-box{
  display: inline-block;
  box-sizing: border-box;
  height: 64px;
  width: 189px;
  border: 1px solid #CCCCCC;
  border-radius: 8px;
  text-align: center;
  line-height: 64px;
  font-size: 28px;
  color: #CCCCCC;
  margin-bottom: 30px;
  margin-right: 30px;
  &.on{
    color: #1F955B;
    border-color: #1F955B;
  }
}
.form-group{
  .check-box:nth-child(3n){
    margin-right: 0;
  }
}
.textarea-out{
  padding-bottom: 30px;
}
.btns{
  width: 100%;
  padding: 40px 0 63px 0;
  .btn{
    width: 540px;
    height: 80px;
    padding: 0;
    background: #1F955B;
    border-radius: 50px;
    margin: 0 auto;
  }
}

.form-check{
  display: flex;
  padding-top: 10px;
  padding-left: 32px;
  .icon{
    padding-right: 24px;
    padding-top: 2px;
    .forn-check{
      box-sizing: border-box;
      width: 30px;
      height: 30px;
      background: #FFFFFF;
      border: 2px solid #CBCBCB;
      border-radius: 50%;
    }
    &.on{
      .forn-check{
        background-color: #1F955B;
        border-color: #1F955B;
      }
    }
  }
  .text{
    height: 36px;
    font-size: 26px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 36px;
  }
}
</style>