<template>
<div class="file-cont">
  <div class="in-cont" :style="bgClass">
    <div class="show-img" v-if="imgUrl">
      <img v-show="BinaryImg" class="show" :src="BinaryImg" alt="">
      <img v-show="! BinaryImg" class="show" :src="imgUrl" alt="">
      <div class="del" @click="delUrl('')">
        <img src="@/assets/image/del.png" alt="">
      </div>
    </div>
    <div class="add" v-show="! imgUrl" @click="upOpen">
      <div class="icon">+</div>
    </div>
  </div>
  <div class="label-cont">
    {{this.label}} <span class="red" v-if="this.master">*</span>
    <div v-if="label2">{{label2}}</div>
  </div>
  <input type="file" @change="upAjax($event)" accept="image/*" ref="file" style="display:none;">

  
</div>
</template>

<script>
import ImageCompressor from 'image-compressor.js'

export default {
  name: 'UpFile',
  props: ['bg', 'master', 'label', 'img', 'label2', 'accept'],
  data () {
    return {
      bgClass: {'background-image': 'url('+this.bg+')'},
      imgUrl: this.img,
      path: this.$init.apis.default + '/access/file/',
      BinaryImg: '', // 二进制图片
    }
  },
  watch: {
    img () {
      if(this.img){
        if(this.img.indexOf('//') >= 0){
          this.imgUrl = this.img;
        }else{
          this.imgUrl = this.path + this.img;
        }
      }else{
        this.imgUrl = '';
      }
    }
  },
  methods: {
    /**
     * 上传触发
     */
    upOpen () {
      this.$refs.file.click()
    },
    /**
     * 图片转BASE64
     */
    toView (file) {
      const vm = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) { 
        vm.BinaryImg = this.result; 
      }
    },
    /**
     * 上传服务
     */
    upAjax (e) {
      const vm = this;
      let file = e.target.files[0];


      new ImageCompressor(file, {
        quality: .6,
        success(result) {      
          const formData = new FormData();
          formData.append('file', result, result.name);
          vm.$axios({
            method: 'post',
            url:  vm.$init.apis.default + "/upload/file",
            data: formData,
            timeout: 600000,
            headers: {
              'Content-Type': 'multipart/form-data',
              'token': vm.$init.pass.token
            }
          }).then(function(response) {
            if (response.data.header.code == "A1000") {
              vm.imgUrl = vm.path+ response.data.body.name;
              vm.upFile(response.data.body.resId);
              vm.toView(result)
            } else {
              vm.$createToast({
                txt: response.data.header.msg,
                type: 'warn'
              }).show()
            }
          }).catch(function(err) {
            vm.$createToast({
              txt: '网络错误',
              type: 'error'
            }).show()
          });
        },
        error(e) {

        },
      });
    },
    /**
     * 删除图片地址
     */
    delUrl () {
      this.imgUrl = '';
      this.$refs.file.value = null;
      this.upFile('')
    },
    /**
     * 更新图片地址
     */
    upFile (url) {
      this.$emit('updata', url);
    }
  }
}
</script>

<style lang="scss" scoped>
.file-cont{
  width: 148px;
}
.in-cont{
  height: 148px;
  width: 148px;
  border-radius: 8px;
  border: 2px solid #E3E3E3;
  box-sizing: border-box;
  position: relative;
  background-size: cover;
}
.label-cont{
  text-align: center;
  font-size: 24px;
  color: #333333;
  line-height: 33px;
  padding: 10px 0;
}
.red{
  color: #E74857;
}
.add{
  height: 148px;
  width: 100%;
  .icon{
    padding: 48px 0 43px 0;
    line-height: 44px;
    font-size: 44px;
    text-align: center;
  }
  .text{
    background: #999999;
    border-radius: 0 0 14px 14px;
    color: #fff;
    font-size: 20px;
    color: #FFFFFF;
    text-align: center;
    line-height: 50px;
  }
}
.show-img{
  border-radius: 14px;
  box-sizing: border-box;
  height: 148px;
  border: 1px solid #999999;
  overflow: hidden;
  position: relative;
  .show{
    display: block;
    width: 100%;
    height: 100%;
  }
  .del{
    position: absolute;
    z-index: 3;
    right: 14px;
    top: 14px;
    img{
      display: block;
      height: 30px;
      width: 30px;
    }
  }
}
</style>