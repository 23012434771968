<template>
<div class="check-cont">
  <div class="check-box" v-for="(item, index) in items" :key="index"
    :class="{'on': value == item.id}"
    @click="click(item.id)"
    >{{item.name}}</div>
</div>
</template>

<script>
export default {
  name: 'formCheck',
  props: ['list', 'val'],
  data () {
    return {
      
    }
  },
  watch: {
    
  },
  methods: {
    click (val) {
      this.$emit('change', val);
    }
  },
  computed: {
    items () {
      return this.list ? this.list : []
    },
    value () {
      return this.val ? this.val : ''
    },
  }
}
</script>

<style lang="scss" scoped>
.check-box{
  display: inline-block;
  box-sizing: border-box;
  height: 64px;
  width: 189px;
  border: 1px solid #CCCCCC;
  border-radius: 14px;
  text-align: center;
  line-height: 64px;
  font-size: 28px;
  color: #CCCCCC;
  margin-bottom: 30px;
  margin-right: 30px;
  &.on{
    color: #1F955B;
    border-color: #1F955B;
    background: url(../assets/image/icon-3.png) no-repeat;
    background-position: 100% 100%;
    background-size: 32px 30px;
  }
}
.check-box:nth-child(3n){
  margin-right: 0;
}
</style>